import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { typography } from 'src/styles'
import EventBlock from 'src/components/EventBlock'
import ComponentRenderer from 'src/components/ComponentRenderer'
import Image from 'src/components/Image'
import Button from 'src/components/Button'
import Grid from 'src/components/Grid'
import TextLockup from 'src/components/TextLockup'
import { util, colors } from 'src/styles'
import { useStaticQuery, graphql } from "gatsby"

const UgcContainer = styled.div`
	${ util.responsiveStyles('padding-top', 50, 50, 50, 52) };
	${ util.responsiveStyles('padding-bottom', 50, 50, 50, 52) };
`

const ItemWrapper = styled.div`
	padding-top: 20px;
	padding-bottom: 68px;
	border-top: 1px solid ${colors.textColor};
`

const ImageWrap = styled(Image)`
	margin-bottom: 20px;

`

const StyledName = styled.p`
	margin-bottom: 0;
`
const StyledInstagram = styled.p`
	margin-top: 5px;
	margin-bottom: 0;
`

const UGCItemsContainer = () => {
	const data = useStaticQuery(
		graphql`
			query {
        allContentfulUgcItem(sort: {fields: createdAt, order: DESC}) {
			    edges {
			      node {
			        comment {
			          comment
			        }
			        image {
			          fluid {
			            aspectRatio
			            src
			            srcSet
			            srcSetWebp
			            srcWebp
			            sizes
			          }
			        }
			        firstName
			        lastName
			        instagramHandle
			        twitterHandle
			      }
			    }
			  }
      }
		`)

	// console.log('Render UGCItemsContainer, Items:', data)
	const items = data.allContentfulUgcItem.edges
	return (
		<UgcContainer>
			<Grid
        small="[12]"
        medium="[4] 1 [4] 1 [4]"
        large="[4] 1 [4] 1 [4]"
        larger="[4] 1 [4] 1 [4]"
        vAlign="top"
      >	
			{
				items && items.map((item, index) => {
					return (
						<ItemWrapper
							key={index}
						>
							{item.node.image ? (
								<ImageWrap
	                image={item.node.image}
	                medium={item.node.medium}
	                small={item.node.small}
	              />
							) : false }

							{item.node.comment.comment ? (
								<TextLockup
									text={item.node.comment.comment}
								/>
							) : false }

							{item.node.firstName ? (
								<StyledName>&#8212;{item.node.firstName} {item.node.lastName ? item.node.lastName : false }</StyledName>
							) : false }

							{item.node.twitterHandle ? (
								<StyledInstagram>{item.node.twitterHandle}</StyledInstagram>
							) : false }

							{item.node.instagramHandle ? (
								<StyledInstagram>{item.node.instagramHandle}</StyledInstagram>
							) : false }
							
						</ItemWrapper>
					)
				})
			}
			</Grid>
		</UgcContainer>
	)
}

export default UGCItemsContainer
