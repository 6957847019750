import React from 'react'

import TextSection from 'src/components/TextSection'
import Columns from 'src/components/Columns'
import WideMedia from 'src/components/WideMedia'
import FiftyFifty from 'src/components/FiftyFifty'
import ProposalLinks from 'src/components/ProposalLinks'
import ParticipateSection from 'src/components/ParticipateSection'
import HistoryScrollEntrance from 'src/components/HistoryScrollEntrance'
import Slideshow from 'src/components/Slideshow'
import ProposalPdfLink from 'src/components/ProposalPdfLink'
import HomeATF from 'src/components/HomeATF'
import SurveyEmbed from 'src/components/SurveyEmbed'
import ChallengesSlideshow from 'src/components/ChallengesSlideshow'
import ChallengesMap from 'src/components/ChallengesMap'
import ChallengesDiagram from 'src/components/ChallengesDiagram'
import WideVideo from 'src/components/WideVideo'

const componentMap = {
	ContentfulTextSection: TextSection,
	ContentfulColumns: Columns,
	ContentfulFiftyFifty: FiftyFifty,
	ContentfulWideMedia: WideMedia,
	ContentfulProposalLinks: ProposalLinks,
	ContentfulSectionTabGroup: ParticipateSection,
	ContentfulSlideshow: Slideshow,
	ContentfulProposalPdfLink: ProposalPdfLink,
	ContentfulHomeAtf: HomeATF,
	ContentfulShareYourThoughtsEmbed: SurveyEmbed,
	// ContentfulChallengesSlideshow: ChallengesSlideshow,
	ContentfulChallengesMap: ChallengesMap,
	ContentfulChallengesDiagram: ChallengesDiagram,
	ContentfulWideVideo: WideVideo

}

export default ({ item, prevTheme, nextTheme, index, isLastSection, isFirstSection, pageType }) => {
	const Component = componentMap[item.__typename]
	if ( pageType === 'history'){
		return Component ? (
			<HistoryScrollEntrance index={index} itemType={item.__typename} key={item.id}>
				<Component
					{...item}
					prevTheme={prevTheme}
					nextTheme={nextTheme}
					isLastSection={isLastSection}
					isFirstSection={isFirstSection}
					index={index}
					pageType={pageType}
					className={pageType}
				/>
			</HistoryScrollEntrance>
		) : null
	} else {
		return Component ? (
			<Component
				{...item}
				prevTheme={prevTheme}
				nextTheme={nextTheme}
				isLastSection={isLastSection}
				isFirstSection={isFirstSection}
				index={index}
				pageType={pageType}
				className={pageType}
			/>
		) : null
	}
}
