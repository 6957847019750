import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Link from 'src/components/Link'
import Button from 'src/components/Button'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Column from 'src/components/Column'
import { mediaQueries as mq, colors, typography } from 'src/styles'

const FFSection = styled(Section)`
`

const ColumnWrapper = styled.div`
  ${ ({ isMedia, vPadded, hPadded, sectionPadded, gutters, index, gridDirection }) => !isMedia ? `
    ${ vPadded ? `` : `` }
    ${ hPadded ? `
      padding-left: ${ 100 / 14 }vw;
      padding-right: ${ 100 / 14 }vw;
      ${ mq.mediumAndUp } {
        padding-left: 0;
        padding-right: 0;
        ${ index === 0 ? `
          ${ gridDirection === 'ltr' ? `
            padding-left: ${ 100 / 14 }vw;
          ` : `
            padding-right: ${ 100 / 14 }vw;
          ` }
        ` : `` }
        ${ index === 1 ? `
          ${ gridDirection === 'ltr' ? `
            padding-right: ${ 100 / 14 }vw;
          ` : `
            padding-left: ${ 100 / 14 }vw;
          ` }
        ` : `` }
      }
    ` : `` }
  ` : `` }
`

const ProposalLinkWrapper = styled.div`
  width: 100%;
  height: 166px;
  position: relative;
  ${ mq.smallAndBelow } {
    height: 120px;
    margin: 4px 0;
  }
`

const ProposalLink = styled(Button)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
	width: 100%;
	height: 166px;
  font-family: ${typography.secondaryFont} !important;
  font-size: 24px !important;
  line-height: 36px !important;
  letter-spacing: unset !important;
  &:hover {
    background: transparent;
    border-color: transparent;
    color: white !important;
    
  }
  z-index: 2;
  ${ mq.smallAndBelow } {
    height: 120px;
    background: transparent;
    border-color: transparent;
    color: white !important;
  }
`

const ProposalLinkImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
  // opacity: 0.5;
  mix-blend-mode: soft-light;

`

const ProposalLinkImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: ${colors.textColor};
`


const ProposalLinks = ({
  className,
  theme,
  prevTheme,
  nextTheme,
  // columns,
  // gutters,
  items,
  width,
  padding,
  // layout,
  // verticalAlignment,
  // columnOrder,
  isFirstSection,
  id
}) => {
  // Set defaults if value is null
  // if (!gutters) { gutters = 'wide' }
  if (!width) { width = 'margins' }
  if (!padding) { padding = 'padded' }
  // if (!layout) { layout = '50/50' }
  // if (!verticalAlignment) { verticalAlignment = 'center' }
  // if (!columnOrder) { columnOrder = 'leftToRight' }

  let fullWidth = false
  if (width === 'fullWidth') {
    fullWidth = true
  }


  let padded = true
  if (padding === 'notPadded') {
    padded = false
  }
  return (
    <FFSection
      className={className}
      setTheme={theme}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      padded={padded}
      isFirstSection={isFirstSection}
    >
      <Grid small={fullWidth ? '[1]' : '1 [12] 1'}>
        <Grid
          small="[1]"
          medium="[1] [1]"
          large="[1] [1]"
          larger="[1] [1]"
          rowGap="2px"
          colGap="2px"
          vAlign="center"
          // gridDirection={gridDirection[columnOrder]}
          // showOverlay={true}
        >
        	{items.map((item, index) => {
        		item.setTheme = 'textColor'
        		return (
        			<ScrollEntrance
								key={index}
								delay={index}
								transitionIn={item.__typename !== 'ContentfulText'}
							>
								<ProposalLinkWrapper>
                  <ProposalLinkImageWrapper>
                    <ProposalLinkImage src={item.seo.shareImage.file.url} />
                  </ProposalLinkImageWrapper>
									<ProposalLink
										{...item}
										delay={index}
										entranceDelay={index}
										to={"/"+item.slug}
										label={item.title}
									/>
                  
								</ProposalLinkWrapper>
							</ScrollEntrance>
      			)
        	})}

      {/*
          {columns.map((column, index) => {
            return (
              <ColumnWrapper
                key={column.id}
                isMedia={!column.content}
                vPadded={column.content && fullWidth}
                hPadded={column.content && fullWidth}
                sectionPadded={padded}
                gutters={gutterSetup[gutters]}
                index={index}
                gridDirection={gridDirection[columnOrder]}
              >
                {column.content ? (
                  <Column
                    delay={index}
                    items={column.content}
                    type={column.type}
                    key={column.id + id}
                  />
                ) : false }

                {column.__typename === 'ContentfulVideo' && (
                  <ScrollEntrance delay={index}>
                    <Video video={column.video} posterImage={column.posterImage} />
                  </ScrollEntrance>
                )}

                {column.__typename === 'ContentfulImage' && (
                  <ScrollEntrance delay={index}>
                    <Image
                      image={column.image}
                      medium={column.medium}
                      small={column.small}
                      sizes={'(max-width: ' + mq.smallBreakpoint + 'px) 100vw, 50vw'}
                      loading={isFirstSection ? 'eager' : 'lazy'}
                    />
                  </ScrollEntrance>
                )}
              </ColumnWrapper>
            )
          })}
        */}
        </Grid>
      </Grid>
    </FFSection>
  )
}

export default ProposalLinks
