import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { typography } from 'src/styles'
import EventBlock from 'src/components/EventBlock'
import ComponentRenderer from 'src/components/ComponentRenderer'
import Button from 'src/components/Button'
import Grid from 'src/components/Grid'
import { util, colors } from 'src/styles'

class EventsContainer extends React.Component {
	state = {
	}
	constructor (props) {
		super(props)
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		
	}

	updateVisibleTab = tab => {
		this.setState({visibleTab: tab})
  }

  render () {
		// const { events, pageType, className } = this.props
		const events = this.props.events.allContentfulEvent.nodes
		return (
			<Fragment>
				<Grid
	        small="1 [12] 1"
	        medium="[5] 1 [5]"
	        large="[5] 1 [5]"
	        larger="[5] 1 [5]"
	        vAlign="stretch"
	        rowGap="75px"
	      >	
				{
					events && events.map((event, index) => {
						return (
								<EventBlock
									eventTitle={event.eventTitle}
									eventDateTime={event.eventDateTime}
									eventDateTimeEnd={event.eventDateTimeEnd}
									eventDescription={event.eventDescription}
									key={event.id}
									slug={event.slug}
								/>
							
						)
					})
				}
				</Grid>
			</Fragment>
		)
	}
}

// Collapse.defaultProps = {
// 	index: null,
// 	collapsed: true
// }


export default EventsContainer
