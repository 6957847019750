import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import ParticipateSection from 'src/components/ParticipateSection'
import ComponentRenderer from 'src/components/ComponentRenderer'
import Button from 'src/components/Button'
import TextLockup from 'src/components/TextLockup'
import { util, colors, typography } from 'src/styles'

const EventWrapper = styled.div`
	h1 {
		font-family: ${typography.primaryFont};
		font-size: 48px;
		margin-bottom: 0;
	}
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const EventInfo = styled.div`

`

const FormattedDate = styled.p`
	font-weight: bold;
	margin: 35px 0;
`

const StyledButton = styled(Button)`
	margin-top: 25px;
	width: 100%;
`

class EventBlock extends React.Component {
	state = {
	}
	constructor (props) {
		super(props)
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		
	}

  render () {
		const { eventTitle, eventDateTime, eventDateTimeEnd, eventDescription, slug } = this.props
		return (
			<EventWrapper>
				<EventInfo>
					<h1>{eventTitle}</h1>
					<FormattedDate>
						<span>{eventDateTime}</span>
						{ eventDateTimeEnd && (
							<span> &#8212; {eventDateTimeEnd}</span>
						)}
					</FormattedDate>


					<TextLockup
						text={eventDescription}
					/>
				</EventInfo>
				<StyledButton
					to={"/"+slug}
					label="More Information"
					setTheme="textColor"
				/>
			</EventWrapper>
		)
	}
}

// Collapse.defaultProps = {
// 	index: null,
// 	collapsed: true
// }


export default EventBlock
