import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import TextLockup from 'src/components/TextLockup'
import Button from 'src/components/Button'
import Grid from 'src/components/Grid'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Column from 'src/components/Column'
import EventsContainer from 'src/components/EventsContainer'
import UGCItemsContainer from 'src/components/UGCItemsContainer'
import ParticipateUgcForm from 'src/components/ParticipateUgcForm'
import { util, colors, typography } from 'src/styles'
import { StaticQuery, graphql } from "gatsby"

import { AnchorLink } from "gatsby-plugin-anchor-links";

const Wrapper = styled(Section)`
	${ util.responsiveStyles('padding-top', 75, 75, 75, 26) }
	${ util.responsiveStyles('padding-bottom', 175, 175, 175, 26) }

`

const SectionText = styled(TextLockup)`
	h1 {
		font-family: ${typography.primaryFont};
		margin-bottom: 50px;
	}
	${ util.responsiveStyles('padding-bottom', 50, 50, 50, 52) };

`

const TakeSurvey = styled.p`
	width: 100%;
	text-align: left;
`

const ShareButton = styled(Button)`
	margin-top: 30px;
	width: 100%;
`

const SurveyLinksWrapper = styled.div`
	padding-top: 75px;
	width: 100%;
	padding-bottom: 25px;
	> div {
		
	}
`

const SurveyLinkButton = styled(Button)`
	width: 100%;
	margin-bottom: 20px;
	line-height: 1.3em;
`

const SurveyAnchorLink = styled(AnchorLink)`
	width: 100%;
	
`

class ParticipateSection extends React.Component {
	state = {
		showUgcForm: false
	}
	constructor (props) {
		super(props)
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		
	}

	showHideForm = () => {
		this.setState(prevState => ({
		  showUgcForm: !prevState.showUgcForm
		}));
	}

	 openInNewTab = (url) => {
  	const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  	if (newWindow) newWindow.opener = null
	}

	renderSectionForm = (sectionType, sectionHeader) => {
		if ( sectionType === 'shareThoughts') {
			return (
				<ParticipateUgcForm sectionHeader={sectionHeader}/>
			)
		} else if ( sectionType === 'signPledge') {
			return (
				<h4>Sign Pledge Placeholder</h4>
			)
		}
	}

	renderEvents = () => {
  		return (
  			<StaticQuery
		      query={graphql`
		        query {
	            allContentfulEvent(sort: {fields: eventDateTime, order: DESC}) {
						    nodes {
						      eventDateTime(formatString:"MMM D YYYY, h:mm a")
						      eventDateTimeEnd(formatString:"MMM D YYYY, h:mm a")
						      eventTitle
						      id
						      eventDescription {
						        json
						      }
						      slug
						    }
						  }
		        }
		      `}
		      render={data => <EventsContainer events={data} />}
		    />
			)
  }

  renderSectionType = (sectionType, sectionHeader, sectionText, sectionCta) => {
  	if (sectionType === 'events'){
  		return (
  			<Grid
	        small="1 [12] 1"
	        medium="1 [12] 1"
	        large="1 [12] 1"
	        larger="1 [12] 1"
	      >	
    		{this.renderEvents()}
    		</Grid>
			)
  	} else if ( sectionType === 'shareThoughts') {
  		if (!this.state.showUgcForm){
  			return (
  				<Fragment>
	      		<Grid
			        small="1 [12] 1"
			        medium='4 [12] 4'
			        large='4 [12] 4'
			        larger='4 [12] 4'
			      >	
			      	<SectionText
		            text={sectionHeader}
		          />
	    		
	        		<SectionText
		            text={sectionText}
		          />
		          <ShareButton
		          	label="Share Your Memories"
		          	setTheme="textColor"
		          	onClick={this.showHideForm}
		          />

		          <SurveyLinksWrapper>

		          	<TakeSurvey>Take our surveys</TakeSurvey>
	          		<SurveyAnchorLink to="/proposals#survey-proposals" title="Our team">
							    <SurveyLinkButton
			          		label="Your Thoughts: Design Ideas"
			          		setTheme="textColor"
		          		/>
							  </SurveyAnchorLink>

							  <SurveyAnchorLink to="/challenges#survey-challenges" title="Our team">
							    <SurveyLinkButton
			          		label="Your Thoughts: Visitor Experience"
			          		setTheme="textColor"
		          		/>
							  </SurveyAnchorLink>

							  <SurveyAnchorLink to="/history#survey-history" title="Our team">
							    <SurveyLinkButton
			          		label="Your Thoughts: Culture and History"
			          		setTheme="textColor"
		          		/>
							  </SurveyAnchorLink>

		          </SurveyLinksWrapper>
	          </Grid>
		          <Grid
				        small="1 [12] 1"
				        medium="1 [12] 1"
				        large="1 [12] 1"
				        larger="1 [12] 1"
				      >	
			          <UGCItemsContainer />
		          </Grid>
	        </Fragment>
				)
  		} else {
  			return (
  				<Fragment>
		  			<Grid
			        small="1 [12] 1"
			        medium='4 [12] 4'
			        large='4 [12] 4'
			        larger='4 [12] 4'
			      >	
		          <ParticipateUgcForm sectionHeader={sectionHeader}/>

		        </Grid>
		        <Grid
			        small="1 [12] 1"
			        medium="1 [12] 1"
			        large="1 [12] 1"
			        larger="1 [12] 1"
			      >	
		          <UGCItemsContainer />
	          </Grid>
          </Fragment>
  			)
  		}
  		
  	} else {
  		return (
	  		<Fragment>
	    		<Grid
		        small="1 [12] 1"
		        medium='4 [12] 4'
		        large='4 [12] 4'
		        larger='4 [12] 4'
		      >	
		      	<SectionText
	            text={sectionHeader}
	          />
	  		
	      		<SectionText
	            text={sectionText}
	          />
	          { sectionCta ? (
		          <ShareButton
		          	label={sectionCta.label}
		          	setTheme="textColor"
	  						onClick={() => {this.openInNewTab(sectionCta.to)}}
		          />

          	) : (
          	// fallback for no cta, hardcoded.
          		<ShareButton
		          	label="Sign The Pledge"
		          	setTheme="textColor"
	  						onClick={() => {this.openInNewTab('https://support.savingplaces.org/page/27558/petition/1?ea.tracking.id=Partner?')}}
		          />
          	)
	        	}
	        </Grid>
	      </Fragment>
			)
  	}
  }


  render () {
		const { className, nextTheme, prevTheme, theme, sectionText, sectionHeader, sectionTitle, sectionType, visibleTab, isFirstSection, id, index, pageType, sectionCta } = this.props

	  // console.log("sectionCta",sectionCta)

	  if (sectionType === visibleTab) {

		  return (
		    <Wrapper
		      className={className}
		      prevTheme={prevTheme}
		      setTheme={theme}
		      nextTheme={nextTheme}
		      isFirstSection={isFirstSection}
		      pageType={pageType}
		      orderIndex={index}
		    >
					{this.renderSectionType(sectionType, sectionHeader, sectionText, sectionCta)}		      	
		    </Wrapper>
		  )
	  } else {
	  	return false
	  }
	}
}



export default ParticipateSection
