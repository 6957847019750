import React from 'react'
import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'
import { util, colors, typography, mediaQueries as mq } from 'src/styles'

const HistoryEnteranceWrap = styled.div`
	${ ({ items, transitionIn, timing, speed, delay, 'data-in-view': inView, transform, orderIndex, itemType, columnOrder }) => transitionIn ? `
		> * {
			transition: transform ${ speed + 'ms' } ${ timing }, opacity ${ speed + 'ms' } ${ timing }, color ${ speed + 'ms' } ${ timing };
			${ inView ? `

			` : `

			` }

			${ delay > 0 ? `
				transition-delay: ${ (speed * 0.115) * (delay) }ms;
			` : `` }
			
			${ Array.isArray(items) ? `
				${ items.map((item, orderIndex) => `
					&:nth-of-type(${ orderIndex + 1 }) {
						transition-delay: ${ (speed * 0.115) * (orderIndex + delay) }ms;
					}
				`) }
			` : `` }
		}
		.history {
			${ itemType === "ContentfulFiftyFifty" && orderIndex != 1 ? `
				${ mq.mediumAndUp } {
					.ContentfulText {
						// p {
						// 	transition: color ${ speed + 'ms' } ${ timing }, border-color ${ speed + 'ms' } ${ timing };
						// 	${ inView ? `
						// 		color: inherit;
						// 		border-color: inherit;
						// 	` : `
						// 		color: white;
						// 		border-color: white !important;
						// 	` }
						// }
					}
			  }
			` : `

			` }
			
		}
	` : `` }
`

const HistoryScrollEntrance = ({ children, className, transform, speed, delay, timing, transitionIn, index, itemType }) => {
	const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.5 })

	if (!children) {
		return false
	}
	// console.log('HistoryScrollEntrance rendered index:', index, itemType)
	return (
		<HistoryEnteranceWrap
			ref={transitionIn ? ref : null}
			delay={delay}
			data-in-view={inView}
			transform={transform}
			className={className}
			items={children}
			timing={timing}
			speed={speed}
			transitionIn={transitionIn}
			orderIndex={index}
			itemType={itemType}
		>
			{children}
		</HistoryEnteranceWrap>
	)
}

HistoryScrollEntrance.defaultProps = {
	transform: 'none',
	delay: 0,
	timing: 'cubic-bezier(0.44, 0.24, 0.16, 1.00)',
	speed: 650,
	transitionIn: true
}

export default HistoryScrollEntrance
