import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Link from 'src/components/Link'
import Button from 'src/components/Button'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Column from 'src/components/Column'
import { mq, util } from 'src/styles'

const ProposalPdfLinkSection = styled(Section)`
	${ util.responsiveStyles('padding-top', 100, 100, 100, 52) };
	${ util.responsiveStyles('padding-bottom', 0, 0, 0, 52) };
`

const ColumnWrapper = styled.div`
  padding-left: ${ 100 / 14 }vw;
  padding-right: ${ 100 / 14 }vw;
  ${ mq.mediumAndUp } {
    padding-left: 0;
    padding-right: 0;
  }
`

const ProposalLink = styled(Button)`
	max-width: 245px;
	margin: 0 auto;
	max-height: 56px;
`

const ProposalPdfLink = ({
  className,
  prevTheme,
  nextTheme,
  // columns,
  // gutters,
  items,
  // layout,
  // verticalAlignment,
  // columnOrder,
  isFirstSection,
  id,
  proposalPdfFile
}) => {
  if ( !proposalPdfFile) {
  	return false
  }

  const openInNewTab = (url) => {
  	const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  	if (newWindow) newWindow.opener = null
	}


  return (
    <ProposalPdfLinkSection
      className={className}
      setTheme={'bgColor'}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      isFirstSection={isFirstSection}
    >
      <Grid
        small="1 [12] 1"
        medium="1 [12] 1"
        large="1 [12] 1"
        larger="1 [12] 1"
        vAlign="center"
        // gridDirection={gridDirection[columnOrder]}
        // showOverlay={true}
      >	
      	<ProposalLink
					label="Full Statement"
					setTheme="textColor"
					onClick={() => {openInNewTab('http:'+proposalPdfFile.file.url)}}
				/>

      </Grid>
    </ProposalPdfLinkSection>
  )
}

export default ProposalPdfLink
