import React from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'

import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Link from 'src/components/Link'
import Button from 'src/components/Button'
import ResponsiveComponent from 'src/components/ResponsiveComponent'

import { mediaQueries as mq, colors, typography, util } from 'src/styles'

import Boathouse from 'src/assets/images/challenges/map/icons/boathouse.svg'
import Cherry from 'src/assets/images/challenges/map/icons/cherry.svg'
import CherryTwo from 'src/assets/images/challenges/map/icons/cherry-2.svg'
import CherryThree from 'src/assets/images/challenges/map/icons/cherry-3.svg'
import Fdr from 'src/assets/images/challenges/map/icons/fdr.svg'
import Floral from 'src/assets/images/challenges/map/icons/floral.svg'
import George from 'src/assets/images/challenges/map/icons/george.svg'
import Jefferson from 'src/assets/images/challenges/map/icons/jefferson.svg'
import Jones from 'src/assets/images/challenges/map/icons/jones.svg'
import Lantern from 'src/assets/images/challenges/map/icons/lantern.svg'
import Mlk from 'src/assets/images/challenges/map/icons/mlk.svg'
import Pagoda from 'src/assets/images/challenges/map/icons/pagoda.svg'
import Bridge from 'src/assets/images/challenges/map/icons/bridge.svg'

const MapSection = styled(Section)`
	${ util.responsiveStyles('padding-top', 175, 175, 175, 52) }
	${ util.responsiveStyles('padding-bottom', 100, 100, 100, 52) }
	${ mq.largerAndUp } {
		padding: 175px ${ 100 / 14 }vw 100px;;
	}
`

const MapWrapper = styled.div`
	// background: bisque;
	position: relative;
	width: 100%;
	${ mq.smallAndBelow } {
		.bridge {
			display: none;
		}
	}
`

const MapPointGroup = styled.div`
	${ mq.mediumAndUp } {

		.map-icon-wrap {
			&:hover {
				& ~ img {
					display: block;
				}
			}
		}
	}
	position: absolute;
	width: ${props => props.width }%;
	top: ${props => props.top }%;
	left: ${props => props.left }%;
	&:hover {
		z-index: 4;
	}

	@media (max-width: 770px) {
		top: ${props => props.topMobile }%;
		left: ${props => props.leftMobile }%;
	}

`

const MapPointLabel = styled.p`
	font-size: 12px;
	line-height: 15px;
	position: absolute;
	top: ${props => props.top }%;
	left: ${props => props.left }%;
	margin: 0;
	

	${ ({singleLine}) => singleLine ? `
		min-width: 7rem;

	` : `
	max-width: 5rem;
	`}
	@media (max-width: 770px) {
		font-size: 9px;
		line-height: 12px;
		max-width: 5rem;
		top: ${props => props.topMobile }%;
		left: ${props => props.leftMobile }%;
	}
`

const MapPointIconWrap = styled.div`
	width: 100%;
	svg {
		width: 100%;
	}

`

const MapPointImage = styled.img`
	display: none;
	position: absolute;
	bottom: calc(100% + 10px);
	left: 50%;
	margin-left: -225px;
	max-width: 450px;
	width: 450px;
	height: auto;
	top: ${props => props.top }%;
	left: ${props => props.left }%;
`

const ChallengesMap = ({
  className,
  prevTheme,
  nextTheme,
  width,
  padding,
  isFirstSection,
  id
}) => {
 	const { mapPng, mobileMapPng, boathouseImg, cherryImg, cherryTwoImg, cherryThreeImg, fdrImg, floralImg, georgeImg, jeffersonImg, jonesImg, lanternImg, mlkImg, pagodaImg, bridgeImg } = useStaticQuery(
		graphql`
			query {
				mapPng: file(relativePath:{eq: "images/challenges/map/map2.png"}) {
					publicURL
				}
				mobileMapPng: file(relativePath:{eq: "images/challenges/map/mobile_map_2.png"}) {
					publicURL
				}
				boathouseImg: file(relativePath:{eq: "images/challenges/map/images/boathouse.jpg"}) {
					publicURL
				}
				cherryImg: file(relativePath:{eq: "images/challenges/map/images/cherry.jpg"}) {
					publicURL
				}
				cherryTwoImg: file(relativePath:{eq: "images/challenges/map/images/cherry-2.jpg"}) {
					publicURL
				}
				fdrImg: file(relativePath:{eq: "images/challenges/map/images/fdr.jpg"}) {
					publicURL
				}
				floralImg: file(relativePath:{eq: "images/challenges/map/images/floral.jpg"}) {
					publicURL
				}
				georgeImg: file(relativePath:{eq: "images/challenges/map/images/george.jpg"}) {
					publicURL
				}
				jeffersonImg: file(relativePath:{eq: "images/challenges/map/images/jefferson.jpg"}) {
					publicURL
				}
				jonesImg: file(relativePath:{eq: "images/challenges/map/images/jones.jpg"}) {
					publicURL
				}
				lanternImg: file(relativePath:{eq: "images/challenges/map/images/lantern.jpg"}) {
					publicURL
				}
				mlkImg: file(relativePath:{eq: "images/challenges/map/images/mlk.jpg"}) {
					publicURL
				}
				pagodaImg: file(relativePath:{eq: "images/challenges/map/images/pagoda.jpg"}) {
					publicURL
				}
				bridgeImg: file(relativePath:{eq: "images/challenges/map/images/bridge.jpg"}) {
					publicURL
				}
			}
		`)
	const host = process.env.HOST || process.env.GATSBY_HOST
  return (
  	<MapSection>
	  	<MapWrapper>
	  		<ResponsiveComponent 
					small={
						<img src={host + mobileMapPng.publicURL} alt="Map" className="map-mobile-png" />
					}
					medium={
				  	<img src={host + mapPng.publicURL} alt="Map" className="map-png" />
					}
				/>
		    

		    <MapPointGroup className="boathouse" width={3.96} top={37} left={60} topMobile={33} leftMobile={74} >
		    	<MapPointIconWrap className="map-icon-wrap"><Boathouse /></MapPointIconWrap>
		    	<MapPointImage className="map-image" src={host + boathouseImg.publicURL} alt="Boathouse" className="boathouse"top={-900} left={-820} />
					<MapPointLabel top={-70} left={130} topMobile={-39} leftMobile={120} singleLine={true}>Boat House</MapPointLabel>
		    </MapPointGroup>

		    <MapPointGroup className="cherry-trees" width={6.65} top={45} left={29.7} topMobile={42} leftMobile={31.7} >
		    	<MapPointIconWrap className="map-icon-wrap"><Cherry /></MapPointIconWrap>
		    	<MapPointImage className="map-image" src={host + cherryImg.publicURL} alt="Boathouse" className="boathouse" top={-550} left={650}/>
					<MapPointLabel top={62} left={-10} topMobile={53} leftMobile={-44}>Cherry<br/>Trees</MapPointLabel>
		    </MapPointGroup>

		    <MapPointGroup className="fdr" width={2.53} top={42} left={25} topMobile={42} leftMobile={22} >
		    	<MapPointIconWrap className="map-icon-wrap"><Fdr /></MapPointIconWrap>
		    	<MapPointImage className="map-image" src={host + fdrImg.publicURL} alt="Boathouse" className="boathouse" top={-400} left={1850}/>
					<MapPointLabel top={-40} left={-250} topMobile={-130} leftMobile={-460}>Franklin Delano<br/>Roosevelt Memorial</MapPointLabel>
		    </MapPointGroup>

		    <MapPointGroup className="mlk" width={3.826} top={15} left={22} topMobile={15} leftMobile={20} >
		    	<MapPointIconWrap className="map-icon-wrap"><Mlk /></MapPointIconWrap>
		    	<MapPointImage className="map-image" src={host + mlkImg.publicURL} alt="Boathouse" className="boathouse" top={290} left={1300}/>
					<MapPointLabel top={-60} left={-120} topMobile={-60} leftMobile={-270}>Martin Luther<br/>King, Jr. Memorial</MapPointLabel>
		    </MapPointGroup>

		    <MapPointGroup className="japanese-lantern" width={1.47} top={15} left={35.5} topMobile={13.5} leftMobile={37} >
		    	<MapPointIconWrap className="map-icon-wrap"><Lantern /></MapPointIconWrap>
		    	<MapPointImage className="map-image" src={host + lanternImg.publicURL} alt="Boathouse" className="boathouse" top={450} left={-570}/>
					<MapPointLabel top={-110} left={-320} topMobile={-80} leftMobile={-500}>Japanese Lantern</MapPointLabel>
		    </MapPointGroup>

		    <MapPointGroup className="jones-memorial" width={2.5} top={3.5} left={43.5} topMobile={2} leftMobile={48.5} >
		    	<MapPointIconWrap className="map-icon-wrap"><Jones /></MapPointIconWrap>
		    	<MapPointImage className="map-image" src={host + jonesImg.publicURL} alt="Boathouse" className="boathouse" top={750} left={-680}/>
					<MapPointLabel top={-100} left={120} topMobile={-100} leftMobile={0} singleLine={true}>John Paul Jones Memorial</MapPointLabel>
		    </MapPointGroup>

		    <MapPointGroup className="floral-library" width={3.39} top={17} left={52} topMobile={16} leftMobile={60} >
		    	<MapPointIconWrap className="map-icon-wrap"><Floral /></MapPointIconWrap>
		    	<MapPointImage className="map-image" src={host + floralImg.publicURL} alt="Boathouse" className="boathouse" top={460} left={-740}/>
					<MapPointLabel top={10} left={110} topMobile={-50} leftMobile={20} singleLine={true}>Floral Library</MapPointLabel>
		    </MapPointGroup>

		 
		    <MapPointGroup className="cherry-three" width={3.79} top={25} left={55} topMobile={24.5} leftMobile={65} >
		    	<MapPointIconWrap className="map-icon-wrap"><CherryTwo /></MapPointIconWrap>
		    	<MapPointImage className="map-image" src={host + cherryTwoImg.publicURL} alt="Boathouse" className="boathouse" top={220} left={-740}/>
		    </MapPointGroup>

		    <MapPointGroup className="cherry-two" width={3.09} top={64} left={37} topMobile={51.5} leftMobile={37.5} >
		    	<MapPointIconWrap className="map-icon-wrap"><CherryTwo /></MapPointIconWrap>
		    	<MapPointImage className="map-image" src={host + cherryImg.publicURL} alt="Boathouse" className="boathouse" top={-1285} left={1250}/>
		    </MapPointGroup>

		    <MapPointGroup className="japanese-pagoda" width={1.45} top={57} left={36.3} topMobile={55.7} leftMobile={38.3} >
		    	<MapPointIconWrap className="map-icon-wrap"><Pagoda /></MapPointIconWrap>
		    	<MapPointImage className="map-image" src={host + pagodaImg.publicURL} alt="Boathouse" className="boathouse" top={-850} left={2400}/>
		    	<MapPointLabel top={10} left={-400} topMobile={33} leftMobile={-710}>Japanese<br/>Pagoda</MapPointLabel>
		    </MapPointGroup>

		    <MapPointGroup className="jefferson-memorial" width={6.12} top={59.5} left={52} topMobile={56.5} leftMobile={62} >
		    	<MapPointIconWrap className="map-icon-wrap"><Jefferson /></MapPointIconWrap>
		    	<MapPointImage className="map-image" src={host + jeffersonImg.publicURL} alt="Boathouse" className="boathouse" top={-800} left={-400}/>
		    	<MapPointLabel top={95} left={10} topMobile={88} leftMobile={5}>Thomas Jefferson Memorial</MapPointLabel>
		    </MapPointGroup>

		    <MapPointGroup className="george-mason" width={4.46} top={76} left={46} topMobile={72} leftMobile={52} >
		    	<MapPointIconWrap className="map-icon-wrap"><George /></MapPointIconWrap>
		    	<MapPointImage className="map-image" src={host + georgeImg.publicURL} alt="Boathouse" className="boathouse" top={-1300} left={-400}/>
		    	<MapPointLabel top={94} left={56} topMobile={80} leftMobile={0} singleLine={true}>George Mason<br/>Memorial</MapPointLabel>
		    </MapPointGroup>

		    <MapPointGroup className="bridge" width={1.6} top={51} left={69} topMobile={47} leftMobile={68.9} >
		    	<MapPointIconWrap className="map-icon-wrap"><Bridge /></MapPointIconWrap>
		    	<MapPointImage className="map-image" src={host + bridgeImg.publicURL} alt="Boathouse" className="boathouse" top={-1200} left={-2670}/>
		    </MapPointGroup>

	    </MapWrapper>
    </MapSection>
  )
}

export default ChallengesMap
