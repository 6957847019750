import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import TextLockup from 'src/components/TextLockup'
import Grid from 'src/components/Grid'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Column from 'src/components/Column'
import { mediaQueries as mq, util, colors, typography } from 'src/styles'


const setPageTypeStyles = (pageType, orderIndex) => `
  ${pageType === 'home' ? `
    ${orderIndex === 1 ? `${ util.responsiveStyles('padding-top', 100, 100, 100, 26) }` : ``}
  `: pageType === 'about' ? `
    ${
      orderIndex === 3 ? `${ util.responsiveStyles('padding-bottom', 100, 100, 100, 26) }` : 
      orderIndex === 5 ? `${ util.responsiveStyles('padding-bottom', 100, 100, 100, 26) }` : 
      orderIndex === 7 ? `${ util.responsiveStyles('padding-top', 100, 100, 100, 26) }; ${ util.responsiveStyles('padding-bottom', 35, 35, 35, 26) }; h6 { font-family: ${typography.primaryFont}; letter-spacing: 0; text-transform: none; font-size: 18px;}` : 
      orderIndex === 9 ? `${ util.responsiveStyles('padding-bottom', 35, 35, 35, 26) }; h6 { font-family: ${typography.primaryFont}; letter-spacing: 0;}` : 
      orderIndex === 10 ? `${ util.responsiveStyles('padding-top', 100, 100, 100, 26) };${ util.responsiveStyles('padding-bottom', 0, 0, 0, 26) }; h6 { font-family: ${typography.primaryFont}; letter-spacing: 0; font-size: 18px; text-transform: none;}` : 
      orderIndex === 18 ? `${ util.responsiveStyles('padding-top', 175, 175, 175, 26) };${ util.responsiveStyles('padding-bottom', 100, 100, 100, 26) }; h6 { font-family: ${typography.primaryFont}; letter-spacing: 0; font-size: 18px; text-transform: none;}` : 
      orderIndex === 22 ? `p { font-family: ${typography.secondaryFont}}` : 
      ``
    }
    ${ mq.mediumAndBelow } {
        h1 {
          font-size: 48px !important;
          text-align: left;
          line-height: 56px !important;
        }
      }
  `: pageType === 'proposals' ?`
    ${
      orderIndex === 2 ? `${ util.responsiveStyles('padding-bottom', 100, 100, 100, 26) }` : 
      orderIndex === 5 ? `${ util.responsiveStyles('padding-bottom', 100, 100, 100, 26) }` : 
      orderIndex === 7 ? `${ util.responsiveStyles('padding-bottom', 35, 35, 35, 26) }; h6 { font-family: ${typography.primaryFont}; letter-spacing: 0;}` : 
      orderIndex === 9 ? `${ util.responsiveStyles('padding-bottom', 35, 35, 35, 26) }; h6 { font-family: ${typography.primaryFont}; letter-spacing: 0;}` : 

      ``
    }
    `: pageType === 'proposal' ? `
    ${
      orderIndex === 8 ? `
        ${ util.responsiveStyles('padding-top', 175, 175, 175, 26) }
        ${ util.responsiveStyles('padding-bottom', 60, 60, 60, 26) }

        ` : 
      ``
    }
  ` : pageType === 'challenges' ? `
      ${ util.responsiveStyles('padding-bottom', 30, 30, 30, 26) }
      // ${ util.responsiveStyles('padding-left', 30, 30, 30, 26) }
  ` : pageType === 'history' ? `  
      ${ mq.mediumAndBelow } {
        h1 {
          text-align: left;
        }
      }

  ` : ``}
  
`
const Wrapper = styled(Section)`
  ${ ({ alignment }) => alignment !== 'right' ? `
    text-align: ${ alignment };
  ` : `` }

  ${ ({ pageType, setTheme, prevTheme }) => pageType === 'about' ? `
    
    ${ setTheme === prevTheme ? `
        ${ util.responsiveStyles('padding-top', 175, 175, 175, 26) }
        ${ util.responsiveStyles('padding-bottom', 100, 100, 100, 26) }
      ` : `
        ${ util.responsiveStyles('padding-top', 175, 175, 175, 52) }
        ${ util.responsiveStyles('padding-bottom', 100, 100, 100, 26) }
    ` }

    h1 {
      margin-bottom: 100px;
    }

  ` : `` }

  ${ ({pageType, orderIndex}) => setPageTypeStyles(pageType, orderIndex)}

  ${ mq.mediumAndBelow } {
    padding-top: 90px;
    padding-bottom: 50px;
    h5 {
      font-size: 28px;
      line-height: 36px;
    }
    
  }

`

const TextSection = ({ className, nextTheme, prevTheme, theme, text, alignment, isFirstSection, id, index, pageType, scrollToTarget, scrollToId }) => {
  if (!text) {
    return false
  }
  const item = text[0]
  const align = {
    // same as in WideMedia.jsx
    left: {
      medium: '1 [9] 4',
      large: '1 [6] 7',
      larger: '1 [6] 7'
    },
    center: {
      medium: '2 [10] 2',
      large: '3 [8] 3',
      larger: '4 [6] 4'
    },
    right: {
      medium: '7 [6] 1',
      large: '8 [5] 1',
      larger: '7 [5] 2'
    }
  }

  if (!alignment || alignment === null) {
    alignment = 'left'
  }
  // console.log('TextSection:',index, pageType)
  return (
    <Wrapper
      className={className}
      prevTheme={prevTheme}
      setTheme={theme}
      nextTheme={nextTheme}
      alignment={alignment}
      isFirstSection={isFirstSection}
      pageType={pageType}
      orderIndex={index}
    >
      <Grid
        small="1 [12] 1"
        medium={pageType === 'challenges' ? '1 [9] 4'  : align[alignment].medium}
        large={pageType === 'challenges' ? '1 [7] 6'  : align[alignment].large}
        larger={pageType === 'challenges' ? '1 [7] 6'  : align[alignment].larger}
      >
        {item.__typename === 'ContentfulText' && (
          <TextLockup
            eyebrow={item.eyebrow}
            headline={item.headline}
            text={item.text}
            headlineSize={item.headlineSize}
            textSize={item.textSize}
            scrollToId={scrollToTarget ? scrollToId : false }
          />
        )}
        {item.__typename === 'ContentfulColumn' && (
          <Column
            items={item.content}
            key={item.id + id}
          />
        )}
      </Grid>
    </Wrapper>
  )
}

export default TextSection
