import React, { useState } from 'react'
import styled from '@emotion/styled'
import Video from 'src/components/Video'
import Section from 'src/components/Section'
import ReactPlayer from 'react-player'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import { colors, mq, util } from 'src/styles'
import { rgba } from 'polished'

import PlayIcon from 'src/assets/images/PlayIcon.svg'

const WideVideoSection = styled(Section)`
  ${ util.responsiveStyles('padding-bottom', 0, 0, 0, 26) }; 
  
  ${ mq.smallAndBelow } {
		padding-top: 90px;
	}
`


const Wrapper = styled.div`
	position: relative;
	padding-bottom: ${ 9/16 * 100 }%;
	background-color: ${colors.textColor};
`

const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	background-color: ${rgba(colors.textColor, 0.7)};
	color: ${colors.white};
	border: 1px solid ${colors.textColor};
	${ util.responsiveStyles('border-width', 80, 60, 40, 20) };
	${ util.responsiveStyles('padding', 50, 30, 26, 20) };
	h1 {
		margin-top: -0.2em;
	}
	${ mq.mediumAndBelow } {
		h1 {
			display: none;
		}
	}
`

const StyledVideo = styled(ReactPlayer)`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;
	z-index: 1;
`

const StyledPlayIcon = styled(PlayIcon)`
	transition: filter 0.3s ease, transform 0.3s ease;
	filter: drop-shadow( 8px 8px 8px rgba(0, 0, 0, 1));
	transform: translate( -50%, -50%);
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	${ util.responsiveStyles('width', 120, 100, 70, 45) };
	height: auto;
	&:hover {
		filter: drop-shadow( 3px 3px 6px rgba(0, 0, 0, .7));
		transform: scale(0.9) translate(-50%, -50%);
	}
`

const StyledOverlayText = styled.h1`
	max-width: 45rem;
`

const MobileHeaderText = styled.h1`
	font-size: 48px;
	color: ${ colors.textColor };
	margin-bottom: 50px;
	margin-left: ${ 100 / 14}vw;
	max-width: 45rem;
`

const WideVideo = ({ className, video, posterImage, wideVideoOverlayText  }) => {
	const [ playing, togglePlaying ] = useState(posterImage.fluid.src)
	// console.log('render wide video')
	return (
		<WideVideoSection>

		<ResponsiveComponent 
			small={
					<MobileHeaderText>{wideVideoOverlayText}</MobileHeaderText>
			}
			large={<span/>}
		/>

	  <Wrapper className={className}>
	  	<StyledVideo
				url={"https://www.youtube.com/watch?v="+video}
				playing={true}
				loop={false}
				muted={false}
				autoPlay={false}
				playIcon={<span />}
				light={playing}
				config={{
					youtube: {
						preload: true,
						playerVars: {
							autoplay: 0,
							color: 'white',
							controls: 1,
							disablekb: 1,
							modestbranding: 1,
							rel: 0
						}
					}
				}}
			/>
			{ playing !== false && (
				<Overlay
					onClick={() =>togglePlaying(false)}
				>	
					{ wideVideoOverlayText && (
						<StyledOverlayText>{wideVideoOverlayText}</StyledOverlayText>
					)}
					<StyledPlayIcon />
				</Overlay>
			)}
	  </Wrapper>
	  </WideVideoSection>
	)
}

export default WideVideo
