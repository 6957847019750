import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Image from 'src/components/Image'
import Video from 'src/components/Video'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Column from 'src/components/Column'
import mediumZoom from 'medium-zoom'

import { util, colors, typography, mediaQueries as mq } from 'src/styles'


const setPageTypeStyles = (pageType, orderIndex) => `
  ${pageType === 'home' ? `
    .home-image-hover {
      .home-image-overlay {
        width: 100%;
        height: 100%;
        background-color: ${colors.textColor};
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        visibility: visible;
        opacity: 1;
        z-index: 3;
        mix-blend-mode: screen;
        transition: visibility 0.5s ease, opacity 0.5s ease;
      }
      img {
        transition: filter 0.5s;
        filter: grayscale(100%);
      }
      &:hover {
        .home-image-overlay {
          visibility: hidden;
          opacity: 0;
        }
        img {
          filter: none;
        }
      }
    }
    ${orderIndex === 2 ? `${ util.responsiveStyles('padding-top', 100, 100, 100, 26) }` : ``}
    ${orderIndex === 3 ? `${ util.responsiveStyles('padding-bottom', 0, 0, 0, 26) }` : ``}
    ${orderIndex === 7 ? `
      ${ util.responsiveStyles('padding-top', 75, 75, 75, 26) }; 
      ${ util.responsiveStyles('padding-bottom', 100, 100, 100, 26) };
      h1 {
        font-family: ${typography.primaryFont};
        font-size: 48px;
        line-height: 56px;
      } 
    ` : ``}

  ` : pageType === 'about' ? `
      ${
        orderIndex === 1 ? `
          ${ util.responsiveStyles('padding-bottom', 0, 0, 0, 52) };
        ` : ``
      }
      ${
        orderIndex === 2 ? `
          ${ util.responsiveStyles('padding-top', 100, 100, 100, 52) };
          ${ util.responsiveStyles('padding-bottom', 0, 0, 0, 52) };
        ` : ``
      }
      ${
        orderIndex === 6 ? `
          ${ util.responsiveStyles('padding-top', 0, 0, 0, 52) };
          ${ util.responsiveStyles('padding-bottom', 0, 0, 0, 52) };
          h6 {
            font-family: ${typography.primaryFont};
            letter-spacing: 0;
            font-weight: 600;
          }
          ${ mq.mediumAndBelow } {
            padding-top: 0;
          }
        ` : ``
      }
      ${
        orderIndex === 8 ? `
          ${ util.responsiveStyles('padding-top', 0, 0, 0, 52) };
          ${ util.responsiveStyles('padding-bottom', 0, 0, 0, 52) };
          h6 {
            font-family: ${typography.primaryFont};
            letter-spacing: 0;
            font-weight: 600;
          }
        ` : ``
      }
      ${
        orderIndex === 9 ? `
          ${ util.responsiveStyles('padding-top', 100, 100, 100, 52) };
          ${ util.responsiveStyles('padding-bottom', 0, 0, 0, 52) };
          h6 {
            font-family: ${typography.primaryFont};
            letter-spacing: 0;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 35px;
          }
        ` : ``
      }
      ${
        orderIndex === 11 ? `
          ${ util.responsiveStyles('padding-top', 35, 35, 35, 52) };
          ${ util.responsiveStyles('padding-bottom', 0, 0, 0, 52) };
          h6 {
            font-family: ${typography.primaryFont};
            letter-spacing: 0;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 35px;
          }
        ` : ``
      }

      ${
        orderIndex === 13 ? `
          ${ util.responsiveStyles('padding-bottom', 0, 0, 0, 52) };
        ` : ``
      }

      ${
        orderIndex === 15 ? `
          ${ util.responsiveStyles('padding-top', 0, 0, 0, 52) };
          ${ util.responsiveStyles('padding-bottom', 0, 0, 0, 52) };
          h6 {
            font-family: ${typography.primaryFont};
            letter-spacing: 0;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 35px
          }
        ` : ``
      }
      ${
        orderIndex === 16 ? `
          ${ util.responsiveStyles('padding-top', 75, 75, 75, 52) };
          ${ util.responsiveStyles('padding-bottom', 0, 0, 0, 52) };
          h6 {
            font-family: ${typography.primaryFont};
            letter-spacing: 0;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 35px;
          }
        ` : ``
      }

      ${
        orderIndex === 17 ? `
          ${ util.responsiveStyles('padding-top', 75, 75, 75, 52) };
          h6 {
            font-family: ${typography.primaryFont};
            letter-spacing: 0;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 35px;
          }
        ` : ``
      }
      ${
        orderIndex === 18 ? `
          ${ util.responsiveStyles('padding-top', 100, 100, 100, 52) };
          ${ util.responsiveStyles('padding-bottom', 0, 0, 0, 52) };
          h6 {
            font-family: ${typography.primaryFont};
            letter-spacing: 0;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 35px
          }
        ` : ``
      }
      ${
        orderIndex === 20 ? `
          ${ util.responsiveStyles('padding-top', 0, 0, 0, 52) };
          ${ util.responsiveStyles('padding-bottom', 0, 0, 0, 52) };
          h6 {
            font-family: ${typography.primaryFont};
            letter-spacing: 0;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 35px

          }
          .ContentfulText {
            h6:nth-of-type(2) {
              margin-top: 75px;
            }
          }
        ` : ``
      }
      ${
        orderIndex === 22 ? `
          ${ util.responsiveStyles('padding-top', 0, 0, 0, 52) };
          ${ util.responsiveStyles('padding-bottom', 0, 0, 0, 52) };
          h6 {
            font-family: ${typography.primaryFont};
            letter-spacing: 0;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 35px
          }
        ` : ``
      }
  ` : pageType === 'history' ? `
      ${ util.responsiveStyles('padding-top', 50, 50, 50, 52) };
      ${ mq.mediumAndBelow } {
        padding-top: 90px;
      }
  `: pageType === 'proposal' ? `
    ${
      orderIndex === 7 ? `${ util.responsiveStyles('padding-top', 118, 118, 118, 26) }; h6 { font-family: ${typography.primaryFont}; letter-spacing: 0;}` 
      : `` }
  ` : pageType === 'challenges' ? `
      .home-image-hover {
        .home-image-overlay {
          width: 100%;
          height: 100%;
          background-color: ${colors.textColor};
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          visibility: visible;
          opacity: 1;
          z-index: 3;
          mix-blend-mode: screen;
          transition: visibility 0.5s ease, opacity 0.5s ease;
        }
        img {
          transition: filter 0.5s;
          filter: grayscale(100%);
        }
        &:hover {
          .home-image-overlay {
            visibility: hidden;
            opacity: 0;
          }
          img {
            filter: none;
          }
        }
      }

      ${
      orderIndex === 5 ? `
        ${ mq.mediumAndBelow } {
          padding-top: 90px;
        }
      ` : `` }

  ` : ``}
  
`

const FFSection = styled(Section)`
  ${ ({pageType, orderIndex}) => setPageTypeStyles(pageType, orderIndex)}

  ${ ({ inATF }) => inATF === true ? `
    ${ util.responsiveStyles('padding-top', 25, 25, 25, 52) };
    ${ util.responsiveStyles('padding-bottom', 13, 13, 13, 52) };
  ` : `` }
  ${ ({ pageType }) => pageType === 'history' ? `
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      border-right: 2px solid ${colors.textColor};
      ${ mq.mediumAndBelow } {
        left: 20px;
      }
    }
  ` : `` }
`

const ColumnWrapper = styled.div`
  ${ ({ isMedia, vPadded, hPadded, sectionPadded, gutters, index, gridDirection }) => !isMedia ? `
    ${ vPadded ? `` : `` }
    ${ hPadded ? `
      padding-left: ${ 100 / 14 }vw;
      padding-right: ${ 100 / 14 }vw;
      ${ mq.mediumAndUp } {
        padding-left: 0;
        padding-right: 0;
        ${ index === 0 ? `
          ${ gridDirection === 'ltr' ? `
            padding-left: ${ 100 / 14 }vw;
          ` : `
            padding-right: ${ 100 / 14 }vw;
          ` }
        ` : `` }
        ${ index === 1 ? `
          ${ gridDirection === 'ltr' ? `
            padding-right: ${ 100 / 14 }vw;
          ` : `
            padding-left: ${ 100 / 14 }vw;
          ` }
        ` : `` }
      }
    ` : `` }
  ` : `` }
  ${ mq.mediumAndBelow } {
    h5 {
      font-size: 28px;
      line-height: 36px;
    }
  }
`

const gridSetup = (layout, gutter) => {
  const layouts = {
    '50/50': {
      medium: '[1]',
      large: '[1] ' + gutter + ' [1]',
      larger: '[1] ' + gutter + ' [1]'
    },
    '60/40': {
      medium: '[1]',
      large: '[6] ' + gutter + ' [5]',
      larger: '[6] ' + gutter + ' [4] 1'
    },
    '40/60': {
      medium: '[1]',
      large: '[5] ' + gutter + ' [6]',
      larger: '1 [4] ' + gutter + ' [6]'
    }
  }

  return layouts[layout]
}

const gutterSetup = {
  narrow: 'g',
  wide: 'm',
  none: ''
}

const gridDirection = {
  leftToRight: 'ltr',
  rightToLeft: 'rtl'
}

const StyledGrid = styled(Grid)`
  
`

const FiftyFifty = ({
  className,
  theme,
  prevTheme,
  nextTheme,
  columns,
  gutters,
  width,
  padding,
  layout,
  verticalAlignment,
  columnOrder,
  isFirstSection,
  id,
  index,
  pageType,
  inATF
}) => {


  // Set defaults if value is null
  if (!gutters) { gutters = 'wide' }
  if (!width) { width = 'margins' }
  if (!padding) { padding = 'padded' }
  if (!layout) { layout = '50/50' }
  if (!verticalAlignment) { verticalAlignment = 'top' }
  if (!columnOrder) { columnOrder = 'leftToRight' }

  let fullWidth = false
  if (width === 'fullWidth') {
    fullWidth = true
  }

  // Flip layout to keep consistent when order is rtl
  if (columnOrder === 'rightToLeft') {
    const firstSide = layout.split('/')[1]
    const secondSide = layout.split('/')[0]
    layout = firstSide + '/' + secondSide
  }

  let padded = true
  if (padding === 'notPadded') {
    padded = false
  }

  const orderIndex = index;

  // console.log('Render FiftyFifty: ', pageType, index)
  const [ zoomImages, updateZoomImages ] = useState(null)

  // useEffect(() => {
  //   // Update the document title using the browser API
  //   // document.title = `You clicked ${count} times`;
  //   const images = typeof document !== `undefined` ? document.querySelectorAll("div.history-zoom > picture > img") : null
  //   if ( images ) {
  //     updateZoomImages(images)
  //   }
  // });

    // var images = document.querySelectorAll("div.slideshow-image > picture > img");

  // if ( zoomImages ) {
  //   mediumZoom(zoomImages, {
  //     background: colors.textColor,
  //     // background: 'bisque',
  //     scrollOffset: 400
  //   })
  // }
  return (
    <FFSection
      className={className + ' index-'+index}
      setTheme={theme}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      padded={padded}
      isFirstSection={isFirstSection}
      orderIndex={index}
      pageType={pageType}
      inATF={inATF}
    >
      <Grid small={fullWidth ? '[1]' : '1 [12] 1'} pageType={pageType}>
        <StyledGrid
          small="[1]"
          medium={gridSetup(layout, gutterSetup[gutters]).medium}
          large={gridSetup(layout, gutterSetup[gutters]).large}
          larger={gridSetup(layout, gutterSetup[gutters]).larger}
          rowGap={['50px', '7vw', '7vw']}
          vAlign={pageType === 'history' ? 'stretch' : verticalAlignment}
          gridDirection={gridDirection[columnOrder]}
          // showOverlay={true}
        >
          {columns.map((column, index) => {
            return (
              <ColumnWrapper
                key={column.id}
                isMedia={!column.content}
                vPadded={column.content && fullWidth}
                hPadded={column.content && fullWidth}
                sectionPadded={padded}
                gutters={gutterSetup[gutters]}
                index={index}
                gridDirection={gridDirection[columnOrder]}

              >
                {column.content ? (
                  <Column
                    delay={index}
                    items={column.content}
                    type={column.type}
                    key={column.id + id}
                    pageType={pageType}
                    orderIndex={orderIndex}
                    columnOrder={columnOrder}
                    showCaptions={pageType === 'home' || pageType === 'challenges'? true : false}
                  />
                ) : false }

                {column.__typename === 'ContentfulVideo' && (
                  <ScrollEntrance delay={index}>
                    <Video video={column.video} posterImage={column.posterImage} />
                  </ScrollEntrance>
                )}

                {column.__typename === 'ContentfulImage' && (
                  <ScrollEntrance delay={index} className={pageType === 'home' || pageType === 'challenges' ? "home-image-hover" : ''}>
                    <Image
                      image={column.image}
                      medium={column.medium}
                      small={column.small}
                      sizes={'(max-width: ' + mq.smallBreakpoint + 'px) 100vw, 50vw'}
                      loading={isFirstSection ? 'eager' : 'lazy'}
                      imageOverlay={true}
                      showCaptions={pageType === 'home' || pageType === 'challenges' ? true : false}
                    />
                  </ScrollEntrance>
                )}
              </ColumnWrapper>
            )
          })}
        </StyledGrid>
      </Grid>
    </FFSection>
  )
}

export default FiftyFifty
