import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import styled from '@emotion/styled'
import SEO from 'src/components/SEO'
import ComponentRenderer from 'src/components/ComponentRenderer'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import WideMedia from 'src/components/WideMedia'
import ParticipateContainer from 'src/components/ParticipateContainer'
import ResponsiveComponent from 'src/components/ResponsiveComponent'


import Button from 'src/components/Button'
import scrollTo from 'gatsby-plugin-smoothscroll';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { util, colors, typography } from 'src/styles'

const propTypes = {
	data: PropTypes.object.isRequired,
}

const ScrollTabsWrapper = styled.div`
	width: 100%;
	display: flex;
	height: 75px;
`

const StyledAnchorLink = styled(AnchorLink)`
	width: 100%;
	flex-grow: 1;
	background-color: ${colors.textColor};
	color: ${colors.white};
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	&:hover {
		background-color: ${colors.white};
		color: ${colors.textColor};
	}
`

const AnchorLinkText = styled.p`
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	max-width: 10rem;
	margin-top: 1em;
	margin-bottom: 1em;
`

class PageTemplate extends React.Component {
	state = {
		homeScrollPercentage: 0
	}
	renderPageType = (layoutType, sections, pageType ) => {
		// console.log('renderPageType | pageType', pageType)

		if ( pageType === 'challenges') {
			return (
					<div className={"page-"+pageType}>
					{
					sections && sections.map((section, index) => {
						const prevTheme = ((index !== 0) && sections[index - 1]) && sections[index - 1].theme
						const prevFullWidth = ((index !== 0) && sections[index - 1]) && sections[index - 1].width === 'fullWidth'
						const nextTheme = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].theme
						const nextFullWidth = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].width === 'fullWidth'
						const lastSection = sections.length === index + 1

							return (
								<ComponentRenderer
									prevTheme={prevFullWidth ? false : prevTheme}
									nextTheme={nextFullWidth ? false : nextTheme}
									isFirstSection={index === 0}
									isLastSection={lastSection}
									key={section.id + index}
									item={section}
									index={index}
									pageType={pageType}
								/>
							)
					})
					}
					</div>
				)
		} else  {

			if ( layoutType === 'scrollTo') {
				// ABOUT PAGE 
				const sect = sections
				if (sect.length > 0) {
					
					const scrollTabs = sect.filter( s => s.__typename === 'ContentfulTextSection' && s.scrollToTarget === true);

					const sects = sect.map((section, index) => {
						const prevTheme = ((index !== 0) && sect[index - 1]) && sect[index - 1].theme
						const prevFullWidth = ((index !== 0) && sect[index - 1]) && sect[index - 1].width === 'fullWidth'
						const nextTheme = ((index !== sect.length - 1) && sect[index + 1]) && sect[index + 1].theme
						const nextFullWidth = ((index !== sect.length - 1) && sect[index + 1]) && sect[index + 1].width === 'fullWidth'
						const lastSection = sect.length === index + 1
						// console.log("section:", section)
						if (section.__typename === 'ContentfulWideMedia'){
							return (
							<Fragment key={index}>
								<WideMedia
									media={section.media}
								  width={section.width}
								  theme={section.theme}
								  caption={section.caption}
								  height={section.height}
								  overlayComponent={section.overlayComponent}
								  overlayPlacement={section.overlayPlacement}
								  overlayTextAlignment={section.overlayTextAlignment}
								  index={section.index}
								  pageType={pageType}
									prevTheme={prevTheme}
									nextTheme={nextTheme}
									isFirstSection={true}
									isLastSection={false}
									key={section.id}
								/>
								<ResponsiveComponent 
									small={<span/>}
									large={
										<ScrollTabsWrapper>	
											{ scrollTabs.map((tab, index) => {
													return (
														<StyledAnchorLink 
															key={index}
															to={"/about#"+tab.scrollToId} 
															title={tab.text[0].text.json.content[0].content[0].value}
														>
															<AnchorLinkText>
																{tab.text[0].text.json.content[0].content[0].value}
															</AnchorLinkText>
														</StyledAnchorLink>
													)
												})
											}
										</ScrollTabsWrapper>
									}
								/>
								
							</Fragment>
							)
						} else {
							return (
								<ComponentRenderer
									prevTheme={prevFullWidth ? false : prevTheme}
									nextTheme={nextFullWidth ? false : nextTheme}
									isFirstSection={index === 0}
									isLastSection={lastSection}
									key={section.id + index}
									item={section}
									index={index}
									pageType={pageType}
								/>
							)
						}
					})

					return (
						<Fragment>
							{sects}
						</Fragment>
					)
				}
				
			} else if (layoutType === 'tabs'){
				// PARTICIPATE PAGE
				const sect = sections
				if (sect.length > 0) {
					return (
						<Fragment>
							<WideMedia
									media={sect[0].media}
								  width={sect[0].width}
								  theme={sect[0].theme}
								  caption={sect[0].caption}
								  height={sect[0].height}
								  overlayComponent={sect[0].overlayComponent}
								  overlayPlacement={sect[0].overlayPlacement}
								  overlayTextAlignment={sect[0].overlayTextAlignment}
								  index={sect[0].index}
								  pageType={pageType}
									isFirstSection={true}
									isLastSection={false}
									key={sect[0].id}
								/>
								<ParticipateContainer
									sections={sect.slice(1)}
									pageType={pageType}
								/>
						</Fragment>
					)
				}

			} else {
				// DEFAULT TEMPLATE

				return (
					<div className={"page-"+pageType}>
					{
					sections && sections.map((section, index) => {
						const prevTheme = ((index !== 0) && sections[index - 1]) && sections[index - 1].theme
						const prevFullWidth = ((index !== 0) && sections[index - 1]) && sections[index - 1].width === 'fullWidth'
						const nextTheme = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].theme
						const nextFullWidth = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].width === 'fullWidth'
						const lastSection = sections.length === index + 1
						return (
							<ComponentRenderer
								prevTheme={prevFullWidth ? false : prevTheme}
								nextTheme={nextFullWidth ? false : nextTheme}
								isFirstSection={index === 0}
								isLastSection={lastSection}
								key={section.id + index}
								item={section}
								index={index}
								pageType={pageType}
							/>
						)
					})
					}
					</div>
				)
			}

		}
	}

	homeHeaderScrollTrigger = (percentage) => {
		this.setState({homeScrollPercentage: percentage});
	}

	render () {
		const site = this.props.data.allContentfulSiteSettings && this.props.data.allContentfulSiteSettings.edges.filter(edge => !edge.node.title.includes('PLACEHOLDER'))[0].node
		const page = this.props.data.allContentfulPage.edges[0].node
		const { sections } = page
		const hasAtf = sections && sections[0].__typename === 'ContentfulWideMedia' && sections[0].width === 'fullWidth'
		const seo = page.seo
    // console.log("page", page)
		return (
			<Fragment >
				<SEO
					title={page.title}
					description={seo.description && seo.description.description}
					siteSettings={site}
					keywords={seo.keywords}
					shareImage={seo.shareImage && 'https:' + seo.shareImage.file.url}
				/>
				<Header
					hasAtf={true}
					bannerText={site.bannerText}
          bannerColor={site.bannerColor}
          navigation={site.navigation}
          pageType={page.pageType}
          location={page.slug}
				/>

				{sections && this.renderPageType(page.layoutType, sections, page.pageType)}
 
				<Footer {...site} />
			</Fragment>
		)
	}
}

PageTemplate.propTypes = propTypes

export const pageQuery = graphql`
  query($id: String!) {
		allContentfulSiteSettings(filter: {internalName: {nin: "PLACEHOLDER Site Settings"}}) {
	    edges {
	      node {
	        ...SiteSettings
	      }
	    }
	  }
    allContentfulPage(filter: {id: {eq: $id}}) {
			edges {
				node {
					id
					title
					slug
					pageType
					layoutType
					sections {
						...Columns
						...FiftyFifty
						...TextSection
						...WideMedia
						...ProposalLinks
						...SectionTabGroup
						...Slideshow
						...ProposalPdfLink
						...HomeAtf
						...ShareYourThoughtsEmbed
						...WideVideo
					}
					seo {
						description {
							description
						}
						keywords
						shareImage {
							file {
								url
							}
						}
					}
				}
			}
    }
	}
`

export default PageTemplate
