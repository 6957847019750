import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Button from 'src/components/Button'
import TextLockup from 'src/components/TextLockup'
import { util, colors, typography, mediaQueries as mq } from 'src/styles'


const Wrapper = styled(Section)`
	${ util.responsiveStyles('padding-top', 75, 75, 75, 26) }
	${ util.responsiveStyles('padding-bottom', 100, 100, 100, 26) }
	> div {
		display: flex;
		flex-direction: column;
		justify-content: center;
	  iframe {
	  	position: absolute;
	  	top: 0;
	  	left: 0;
	  	right: 0;
	  	bottom: 0;
	  	width: 100%;
	  	min-width: 660px;
	  	height: 100% !important;
	  	${ mq.smallAndBelow } {
				min-width: 300px;
			}
	  }
	}
`

const EmbedWrapper = styled.div`
	width: 100%;
	height: 100%;
`
const FormHeaderWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-grow: 0;
	flex-shrink: 0;
`

const IframeWrapper = styled.div`
	flex-grow: 1;
	min-height: 200vw;
	position: relative;
	${ mq.smallAndBelow } {
		min-height: 200vw;
	}

`

const FormHeader = styled.h1`

	font-family: ${typography.primaryFont};
	
`

const StyledFormHeader = styled.h1`
	font-family: ${typography.primaryFont};
	max-width: 50rem;
	${ mq.smallAndBelow } {
		font-size: 28px;
		padding-left: ${ 100 / 14 }vw;
	}
`


const EmbedScriptBody = {__html: '<script type="text/javascript" src="https://savingplaces.formstack.com/forms/js.php/ideas_lab_survey_proposals?nojquery=1&nojqueryui=1&nomodernizr=1&no_style=1"></script><noscript><a href="https://savingplaces.formstack.com/forms/ideas_lab_survey_proposals" title="Online Form">Online Form - Tidal Basin Ideas Lab survey - Proposals</a></noscript><script type="text/javascript">if (typeof $ == "undefined" && jQuery){ $ = jQuery}</script>'}

class SurveyEmbed extends React.Component {
	state = {
	}
	constructor (props) {
		super(props)
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		
	}

	componentDidMount () {
		// const script = document.createElement("script");

  //   script.src = "js/myScript.js";
  //   script.async = true;

  //   document.body.appendChild(script);
	}
	


  render () {
		const { surveyType } = this.props
		// console.log('Render SurveyEmbed, ', surveyType);
		const formHeader = {
			challenges: 'Your Thoughts: Visitor Experience',
			history: 'Your Thoughts: Culture and History',
			proposals: 'Your Thoughts: Design Ideas'
		}
		return (
			<Wrapper setTheme="mainColor" type={surveyType}>
				{/*}
				*/}
			<FormHeaderWrapper id={"survey-"+surveyType}>
				<StyledFormHeader>
					{formHeader[surveyType]}
				</StyledFormHeader>
			</FormHeaderWrapper>
				<IframeWrapper>
					<iframe src={"https://savingplaces.formstack.com/forms/ideas_lab_survey_" + surveyType} title="Tidal Basin Ideas Lab survey - Proposals"></iframe>
				</IframeWrapper>
			</Wrapper>
		)
	}
}

// Collapse.defaultProps = {
// 	index: null,
// 	collapsed: true
// }


export default SurveyEmbed
