import React from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'

import ResponsiveComponent from 'src/components/ResponsiveComponent'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Link from 'src/components/Link'
import Button from 'src/components/Button'
import { mediaQueries as mq, colors, typography, util } from 'src/styles'

const Wrapper = styled.div`
	display: flex;
	padding-top: 0;
	padding-bottom: 50px;
	position: relative;
	@media (max-width: 770px) {
		margin-top: -10%;
	}
`

const GuideWrapper = styled.div`
	width: 100px;
	flex-grow: 0;
	flex-shrink: 0;
	// background: red;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: 10.75%;
	// padding-bottom: 0.25%;
	position: relative;
	@media (max-width: 770px) {
		width: 55px;
		padding-top: 21.75%;
	}
`

const Elevation = styled.p`
	font-size: 12px;
	position: absolute;
	top: calc(50% - 10px);
	left: 20px;
  transform: rotate(-90deg);
  @media (max-width: 770px) {
		left: -5px;
	}
`

const NotchWrapper = styled.div`
	width: 100%;
	padding-right: 2px;
	padding-left: 70%;
	p {
		margin-top: 0;
		margin-bottom: 0;
		align-self: flex-end;
		color: ${colors.textColor};
		font-size: 12px;
		text-align: right;
		line-height: 5px;
		display: flex;
		flex-direction: row;
	}
	p:after { 
    content: ""; 
    flex: 1 1;
    border-bottom: 1px solid ${colors.textColor}; 
    margin: auto; 
  }
  @media (max-width: 770px) {
		padding-left: 60%;

		p {
			font-size: 10px;
		}
	}
`

const StyledFlexDiv = styled.div`
	flex-grow: 1;
	position: relative;
	width: 1px;
`

const ChartWrapper = styled.div`
	position: relative;
	flex-grow: 1;
	@media (max-width: 770px)  {
    overflow-x: auto;

    img {
    	width: 200%;
    	max-width: none;
    	z-index: 1;
	  }
  }
`

const ChartOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
`

const OverlayItem = styled.p`
	position: absolute;
	top: ${props => props.top }%;
	left: ${props => props.left }%;
	margin: 0;
	${ util.responsiveStyles('font-size', 18, 16, 14, 10) };
	line-height: 1.1em;
	&.white {
		color: ${ colors.white };
	}

	&.blue {
		color: #1fc4f4;
	}

`


const ChallengesDiagram = ({ className }) => {

	const { chartDesktop, chartMobile } = useStaticQuery(
		graphql`
			query {
				chartDesktop: file(relativePath:{eq: "images/challenges/diagram/TB_desktop_crop_2.png"}) {
					publicURL
				}
				chartMobile: file(relativePath:{eq: "images/challenges/diagram/TB_mobile_crop.png"}) {
					publicURL
				}
			}
		`)
	const host = process.env.HOST || process.env.GATSBY_HOST

	return (

  <Wrapper className={className}>
  	<GuideWrapper>
			<Elevation>Elevation</Elevation>
			<NotchWrapper><p>12'&nbsp;</p></NotchWrapper>
			<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
			<NotchWrapper><p>10'&nbsp;</p></NotchWrapper>
			<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
			<NotchWrapper><p>8'&nbsp;</p></NotchWrapper>
			<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
			<NotchWrapper><p>6'&nbsp;</p></NotchWrapper>
			<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
			<NotchWrapper><p>4'&nbsp;</p></NotchWrapper>
			<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
			<NotchWrapper><p>2'&nbsp;</p></NotchWrapper>
			<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
			<NotchWrapper><p>0'&nbsp;</p></NotchWrapper>
			<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
			<NotchWrapper><p>-2'&nbsp;</p></NotchWrapper>
			<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
			<NotchWrapper><p>-4'&nbsp;</p></NotchWrapper>
			<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
			<NotchWrapper><p>-6'</p></NotchWrapper>
			<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
		</GuideWrapper>
		<StyledFlexDiv>
			<ChartWrapper>
				<img src={host + chartDesktop.publicURL} alt="Chart" className="chart" />
		  		<OverlayItem top={59.5}  left={3}>
		  			Current High Tide: 3'
		  		</OverlayItem>
		  		<OverlayItem top={74} left={3}>
		  			Current Low Tide: 0'
		  		</OverlayItem>
		  		<OverlayItem top={96.5} left={3}>
		  			Approx. Max. Bottom of Basin: -6.90'
		  		</OverlayItem>
		  		<OverlayItem top={52.5}  left={3} className="white">
		  			Average Elevation of the Tidal Basin Hard Edge: 3.52'
		  		</OverlayItem>
		  		<OverlayItem top={40.5}  left={3} className="blue">
		  			20 Year Prediction:<br/>Average +1.8' Relative Sea Level Rise<br/>High Tide: 4.8'
		  		</OverlayItem>
		  		<OverlayItem top={23} left={3} className="blue">
		  			50 Year Prediction:<br/>Average +6' Relative Sea Level Rise<br/>High Tide: 9'
		  		</OverlayItem>
			{/*
				<ResponsiveComponent 
					small={
						<img src={host + chartMobile.publicURL} alt="Chart" className="chart-mobile" />
					}
					medium={
				  	<img src={host + chartDesktop.publicURL} alt="Chart" className="chart" />
					}
				/>
				<ResponsiveComponent 
					small={
						<ChartOverlay>
				  		<OverlayItem bottom={26.5} left={1}>
				  			<p>Current High Tide: 3'</p>
				  		</OverlayItem>
				  		<OverlayItem bottom={17} left={1}>
				  			<p>Current Low Tide: 0'</p>
				  		</OverlayItem>
				  		<OverlayItem bottom={1} left={1}>
				  			<p>Approx. Max. Bottom of Basin: -6.90'</p>
				  		</OverlayItem>
				  		<OverlayItem bottom={24} left={52} className="white mobile">
				  			<p>Average Elevation of the Tidal Basin Hard Edge: 3.52'</p>
				  		</OverlayItem>
				  		<OverlayItem  bottom={36} left={1} className="blue">
				  			<p>20 Year Prediction:<br/>Average +1.8' Relative Sea Level Rise<br/>High Tide: 4.8'</p>
				  		</OverlayItem>
				  		<OverlayItem bottom={50} left={1} className="blue">
				  			<p>50 Year Prediction:<br/>Average +6' Relative Sea Level Rise<br/>High Tide: 9'</p>
				  		</OverlayItem>
				  	</ChartOverlay>
					}
					medium={
				  	
					}
				/>
			*/}

				

		  	
	  	</ChartWrapper>
  	</StyledFlexDiv>
	</Wrapper>
)}

export default ChallengesDiagram
