import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { typography } from 'src/styles'
import ParticipateSection from 'src/components/ParticipateSection'
import ComponentRenderer from 'src/components/ComponentRenderer'
import Button from 'src/components/Button'
import { util, colors, mediaQueries as mq } from 'src/styles'

const TabWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 75px;
	border-bottom: 2px solid ${colors.textColor};
	background-color: ${colors.mainColor};
	align-items: stretch;
	${ mq.smallAndBelow } {
		height: 77px;
	}
`

const TabButton = styled(Button)`
	flex-grow: 1;
	height: 74px;
	${ mq.smallAndBelow } {
		max-width: 34vw;
		height: 75px;
		margin: 0;
	}
	
`

class ParticipateContainer extends React.Component {
	state = {
		visibleTab: 'shareThoughts'
	}
	constructor (props) {
		super(props)
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		
	}

	updateVisibleTab = tab => {
		this.setState({visibleTab: tab})
  }

  render () {
		const { sections, pageType, className } = this.props
		// console.log('Render ParticipateContainer, sections:', sections)
		if (sections) {
			return (
				<Fragment>
				<TabWrapper>
					{
					sections && sections.map((section, index) => {
						
						return (
							<TabButton
								onClick={() => this.updateVisibleTab(section.sectionType)}
								label={section.sectionTitle}
								setTheme={this.state.visibleTab === section.sectionType ? 'mainColorAltActive' : 'mainColorAlt'}
								theme={this.state.visibleTab === section.sectionType ? 'mainColorAltActive' : 'mainColorAlt'}
								key={index}
							/>
						)
					})
					}
				</TabWrapper>
				{
				sections && sections.map((section, index) => {
					const prevTheme = ((index !== 0) && sections[index - 1]) && sections[index - 1].theme
					const prevFullWidth = ((index !== 0) && sections[index - 1]) && sections[index - 1].width === 'fullWidth'
					const nextTheme = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].theme
					const nextFullWidth = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].width === 'fullWidth'
					const lastSection = sections.length === index + 1
					return (
						<ParticipateSection
							{...section}
							prevTheme={prevFullWidth ? false : prevTheme}
							nextTheme={nextFullWidth ? false : nextTheme}
							isFirstSection={index === 0}
							isLastSection={lastSection}
							key={section.id + index}
							index={index}
							pageType={pageType}
							visibleTab={this.state.visibleTab}
						/>
						
					)
				})
				}
				</Fragment>
			)
		} 
	}
}

// Collapse.defaultProps = {
// 	index: null,
// 	collapsed: true
// }


export default ParticipateContainer
