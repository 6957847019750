import React from "react";
import styled from '@emotion/styled'
import MaterialIcon from 'src/components/MaterialIcon'

const StyledLabel = styled.label`
	display: flex;
	p {
		margin-top: 0;
		margin-bottom: 0;
		margin-left: 10px;
	}
`

const StyledCheckbox = styled(MaterialIcon)`
	flex-shrink: 0;
	flex-grow: 0;
	margin-left: -2px;
`


const Checkbox = ({ label, name, isSelected, onCheckboxChange }) => (
  <div className="form-check">
    <input
      type="checkbox"
      name={name}
      checked={isSelected}
      onChange={onCheckboxChange}
      className="form-check-input"
      id={name}
    />
    <StyledLabel htmlFor={name}>
    	<StyledCheckbox>
    		{isSelected ? "check_box" : "check_box_outline_blank"}
    	</StyledCheckbox>
      <p>{label}</p>
    </StyledLabel>
  </div>
);

export default Checkbox;