import React from 'react'
import styled from '@emotion/styled'
import TextLockup from 'src/components/TextLockup'
import Link from 'src/components/Link'
import Image from 'src/components/Image'
import Video from 'src/components/Video'
import List from 'src/components/List'
import Button from 'src/components/Button'
import Card from 'src/components/Card'
import ScrollEntrance from 'src/components/ScrollEntrance'
import { util, colors, typography, mediaQueries as mq } from 'src/styles'

const componentMap = {
	ContentfulText: TextLockup,
	ContentfulLink: {
		default: Link,
		textLink: Link,
		button: Button
	},
	ContentfulImage: Image,
	ContentfulVideo: Video,
	ContentfulList: {
		default: List,
		horizontal: List,
		vertical: List,
		checkList: List
	}
}

const ColumnContent = styled.div`
	> div {
	  &:not(:first-of-type) {
	  	${ util.responsiveStyles('margin-top', 30, 24, 20, 16) }
	  }
  }

  ${ ({ pageType }) => pageType === 'about' ? `
  	h1 {
  		margin-bottom: 100px
  	}
  	${ mq.mediumAndBelow } {
      h1 {
      	margin-bottom: 50px;
      }
    }
  ` : `` }

  ${ ({ pageType, orderIndex, columnOrder }) => pageType === 'history' ? `
  	position: sticky;
  	top: 200px;
  	${
      orderIndex != 1 && orderIndex != 0 ? `
	      ${ mq.mediumAndBelow } {
			    padding-left: 50px;
			  }
      ` : ``}
			


  	> div {
  		&:nth-of-type(2) {
  			.ContentfulText {
  				p {
  					border-top: 1.5px solid;
  					border-color: ${colors.textColor};
  					font-family: ${typography.secondaryFont};
  					font-size: 14px;
  					line-height: 18px;
  					padding-top: 18px;
  					${columnOrder === 'rightToLeft' ? `
  						margin-left: -${ 100 / 14 }vw;
  						padding-left: ${ 100 / 14 }vw;
  						max-width: 80%;
  						${ mq.mediumAndBelow } {
						    margin-left: 0;
						    padding-left: 0;
						  }
  					` : `
	  					margin-left: 35%;
	  					
  					`}

  					${ mq.mediumAndBelow } {
					    margin-left: 15%;
					  }


  				}
  			}
  		}
  	}
  	
  ` : `` }
`

const RenderContent = ({ items, delay, pageType }) => {
	// console.log('Render column Content :', pageType, items)
	return (
		items.map((item, index) => {
			let showCaptions = false;
			let setZoom = ''

			if (item.__typename === 'ContentfulImage') {
				if ( pageType === 'history' || pageType === 'home' || pageType === 'challenges') {
					showCaptions = true;
				}

				if ( pageType === 'history' ) {
					setZoom = "history-zoom"
				}
			}

			let Component = componentMap[item.__typename]
			if (item.type || item.type === null) {
				Component = componentMap[item.__typename][item.type]
				if (!Component) {
					Component = componentMap[item.__typename].default
				}
			}
			if (item.type === 'button'){
				item.setTheme = item.theme
			}
			return Component ? (
				<ScrollEntrance
					key={item.id}
					delay={index + delay}
					transitionIn={item.__typename !== 'ContentfulText'}
					className={item.__typename === 'ContentfulImage' && pageType === 'home'  || pageType === 'challenges' ? "home-image-hover" : ''}
				>
					<div>
						<Component
							{...item}
							delay={index + delay}
							entranceDelay={index + delay}
							className={item.__typename + " " + setZoom}
							showCaptions={showCaptions}
							imageOverlay={item.__typename === 'ContentfulImage' && pageType === 'home' || pageType === 'challenges' ? true : false}
							pageType={pageType}
						/>
					</div>
				</ScrollEntrance>
			) : false
		})
	)
}

const Column = ({ items, delay, type, pageType, orderIndex, columnOrder }) => {
	if (!items) {
		return false
	}

	if (type === null) {
		type = 'default'
	}
	return (
		<ColumnContent as={type.startsWith('card') ? Card : 'div'} type={pageType} pageType={pageType} orderIndex={orderIndex} className={pageType} columnOrder={columnOrder}>
			<RenderContent items={items} delay={delay} pageType={pageType}/>
		</ColumnContent>
	)
}

Column.defaultProps = {
	type: 'default'
}

export default Column
